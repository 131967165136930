import React, { useEffect, useState } from 'react'
import s from './AllUsers.module.sass'
import axios from '../../axios'

function AllUsers() {

    const [users, setUsers] = useState([])
    const [opened, setOpened] = useState(null) // null вместо undefined

    useEffect(() => {
        axios.get('/getAllUsers')
        .then(res => res.data)
        .then(data => {
            console.log(data)
            setUsers(data)
        })
    }, [])

    return (
        <div className={s.container}>
            <div className={s.users}>
                <p className={s.text}><b>Итого: {users?.length} человек</b></p>
                {
                    users && users.map((user, index) => 
                        <div className={s.peopleWrapper} key={index}> {/* React.Fragment вместо пустого тега */}
                            <div className={s.people} onClick={() => opened !== index ? setOpened(index) : setOpened(null)}>
                                <p className={s.name}>{user.name && user.name != "" ? user.name : "(не указано имя)"}</p>
                                <p>{user?.phone}{'\u00A0 \u00A0'}{'\u00A0 \u00A0'}{'\u00A0 \u00A0'}{'\u00A0 \u00A0'}</p>
                                
                            </div>
                                    <div className={s.nominations}>
                                        {
                                            user.applications.length === 0 
                                                ? <p>Нету поданных заявок</p>
                                                : user.applications.map((elem, i) => <p key={i}>{elem.application_data.nomination} {'\u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0'}{ elem.application_data.accepted == true ? "Одобрена" : "не одобрена"}</p>)
                                        }
                                    </div>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default AllUsers
